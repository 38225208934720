
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISurvey, ISurveySettings } from '@/interfaces/survey'

@Component
export default class SurveySettingsDialog extends Vue {
  @Prop() survey!: ISurvey

  formData: ISurveySettings = {
    show_progress_text: true,
    background_color: '#ffffffff',
    ...(this.survey.settings || {})
  }

  saveForm() {
    this.$emit('save', { item: this.survey, settings: this.formData })
    this.$emit('close')
  }
}
