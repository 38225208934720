
import { Component, Vue } from 'vue-property-decorator'
import { ISurvey, ISurveySettings } from '@/interfaces/survey'
import surveyModule from '@/store/survey'
import SurveySettings from '@/components/SurveySettings.vue'
import Confirm from '@/components/Confirm.vue'

@Component({
  components: {
    SurveySettings,
    Confirm
  }
})
export default class SurveyListView extends Vue {
  headers = [
    {
      text: 'Title',
      sortable: true,
      value: 'title',
      align: 'left'
    },
    {
      text: 'Created At',
      sortable: true,
      value: 'created_at',
      align: 'left'
    },
    {
      text: 'Responses',
      value: 'responses_data'
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'is_active',
      align: 'left'
    },
    {
      text: 'Actions',
      value: 'id'
    }
  ]

  get items(): ISurvey[] {
    return surveyModule.orderedItems()
  }

  changeStatus(item: ISurvey): void {
    surveyModule.actionUpdate({ ...item, is_active: !item.is_active })
  }

  saveSettings(payload: { item: ISurvey; settings: ISurveySettings }): void {
    surveyModule.actionUpdate({ ...payload.item, settings: payload.settings })
  }

  async deleteSurvey(item: ISurvey): Promise<void> {
    if (await (this.$refs.confirm as Confirm).open('Are you sure?', { color: 'red' })) {
      // yes
      surveyModule.actionDelete({ ...item })
    } else {
      // cancel
    }
  }
}
